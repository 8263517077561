import Dexie from 'dexie';
import * as encrypt from 'dexie-encrypted';
import Plugin from "../abstract";
import Model from './model';
import Repository from './repository';

export {
  Model,
  Repository
}
/**
 * @type kernel.database
 * @extends kernelBundle
 */
export default class Database extends Plugin {

  /**
   * @type {string}
   * @private
   */
  _name = 'database';
  /**
   *
   * @type {boolean}
   * @private
   */
  __databaseReady = false;
  /**
   * @type {Object<String, String>}
   */
  stores = {};
  /**
   * @type {Object<kernel.database.Model.constructor.entityName, kernel.database.Model >}
   */
  classes = {};
  /**
   * @type {Object<String, Object>}
   */
  encryptions = {};
  /**
   *
   * @type {Object<String, kernel.database.Repository>}
   */
  repositories = {};
  /**
   *
   * @type {kernel.database.eventHandlers|Object<String, Array>}
   */
  eventHandlers = {
    table_added: [],
    table_ready: [],
    table_removed: [],
    table_updated: [],
    database_ready: []
  };

  /**
   * @inheritDoc
   */
  constructor(config, $kernel) {
    super(config, $kernel);
    this.config = config;
    this.$kernel = $kernel;
  }

  get key() {
    const binary_string = this._key.substr(0, 32);
    const len = binary_string.length;
    const cryptoKey = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      cryptoKey[i] = binary_string.charCodeAt(i);
    }
    return cryptoKey;
  }

  /**
   *
   * @param {(() => Promise<{readonly default?: Model}>)[]} fns
   * @return {Promise<Database>}
   */
  async addModels(fns) {
    for (const fn of fns) {
      await this.addModel(fn)
    }
    return this
  }

  /**
   * @param {() => Promise<{readonly default?: Model}>} fn
   * @return {Database}
   */
  async addModel(fn) {
    const {default: Class} = await fn()
    this.stores[Class.entityName] = Class.store;
    if (Class.encryption && Class.encryption !== Model.NO_ENCRYPTION) {
      this.encryptions[Class.entityName] = Class.encryption;
    }
    this.classes[Class.entityName] = Class;
    this.repositories[Class.entityName] = new Repository(Class, this);
    this._fireEvent('table_' + Class.entityName + '_added', Class.entityName);
    return this;
  }

  /**
   * insert new definitions
   * @return {Dexie.Version.prototype}
   */
  updateDatabase() {
    // noinspection JSValidateTypes
    let version;
    try {
      version = this._d.version(this.config.version);
    } catch (e) {
      version = this._d.version(this.config.version);
    }
    return version;
  }

  /**
   * create a new database
   * @param {string} [key] encryption key. if given, database will be encrypted has to minimum length of 32. String will be truncated after 32
   * @return {Promise<kernel.database|this>}
   */
  instanceDatabase(key = undefined) {
    return new Promise((resolve) => {
      this._d = new Dexie(this.config.entityName);
      if (key && key.length >= 32) {
        this._key = key;
        // noinspection JSCheckFunctionSignatures
        encrypt.applyEncryptionMiddleware(this._d, this.key, this.encryptions, this.onEncryptionKeyChange);
      }
      // noinspection JSUnresolvedFunction
      this.updateDatabase().stores(this.stores);
      Object.keys(this.stores).map(key => {
        this.classes[key] && this.mapClass(this.classes[key]);
      });
      if (!this.__databaseReady) {
        this._fireEvent('database_ready', this.config.entityName);
        Object.keys(this.classes).map(key => {
          this._fireEvent('table_ready', key);
        });
        this.__databaseReady = true;
      }

      resolve(this);
    });
  }

  /**
   * Class
   * @param {kernel.database.Model} Class
   */
  mapClass(Class) {
    this._d[Class.entityName].mapToClass(Class);
    return this;
  }

  /**
   * returns a table with given name
   * @param {String} name
   * @return {Dexie.Table}
   */
  table(name) {
    return this._d.table(name)
  }

  /**
   * @param {string} ClassName
   * @return {Repository}
   */
  getRepository(ClassName) {
    return this.repositories[ClassName];
  }

  /**
   * @param {kernel.database.EventTypes|string} type
   * @param {Model.constructor.entityName|string} tableName
   * @param {kernel.database.EventHandler} handler
   * @param {boolean} once
   * @param {boolean} immediate
   */
  addEventListener(type, tableName, handler, once = false, immediate = true) {
    !this.eventHandlers[type] && (this.eventHandlers[type] = {});
    !this.eventHandlers[type][tableName] && (this.eventHandlers[type][tableName] = []);
    this.eventHandlers[type][tableName].push({handler, once});
    immediate && this._fireEvent(type, tableName);
  }

  /**
   * @param {kernel.database.EventTypes|string} type
   * @param {Model.constructor.entityName|string} tableName
   * @private
   */
  _fireEvent(type, tableName, detail) {
    this.eventHandlers[type] && Object.entries(this.eventHandlers[type]).map(([key, handlers], index) => {
      handlers.map(({handler, once}) => {
        // noinspection JSIncompatibleTypesComparison
        this.repositories[tableName] instanceof Repository && handler(tableName, type === 'database_ready' ? this.repositories : this.repositories[tableName], detail);
        if (once) this.eventHandlers[type][key].splice(index, 1);
      });
    });
  }

  /**
   *
   * @param database
   */
  onEncryptionKeyChange(database) {
    console.log('DATABASE - onEncryptionKeyChange', arguments);
  }
}
