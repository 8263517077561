<template>
  <nav ref="nav" class="navbar" @click="collapse">
    <div class="container ps-lg-0">
      <router-link
          :to="$user.loggedIn ? '/home' : '/login'"
          class="navbar-icon d-block"
          v-html="logoIcon"
      />
      <div
          class="navbar-toggler burger-menu-button d-lg-none"
          @click="toggle"
          v-html="burgerMenuIcon"
      />
      <span v-if="$navbar.badge" class="badge badge-pill badge-primary">{{ $navbar.badge }}</span>
      <div id="nav" ref="navbarNav" class="navbar-collapse p-0 d-lg-none">
        <ul class="navbar-nav">
          <li v-for="(link, key) in links" :key="key"
              :class="link.path === $router.currentRoute.path ? 'nav-item active' : 'nav-item'" @mouseup="collapse">
            <router-link :to="link.path" class="nav-link text-center py-4 position-relative">{{ $t(link.name) }}
              <span v-if="link.meta.badge" class="badge badge-pill badge-primary">{{ link.meta.badge }}</span>
            </router-link>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
import {Collapse} from "bootstrap";

export default {
  name: 'NavComponent',
  data() {
    return {
      Route: {},
      isOpen: false,
      links: [],
      navbar: {}
    };
  },
  props: {
    logoIcon: {
      type: String,
      default: ''
    },
    arrowIcon:{
      type: String,
      default: ''
    },
    burgerMenuIcon:{
      type: String,
      default: ''
    },
  },
  created() {
    this.$navbar.component = this
  },
  mounted() {
    if(window.cordova.platformId === 'browser') {
      setInterval(async () => {
        this.$page.loading = true
        await this.$root.refresh()
        this.$page.loading = false
      }, 1000 * 60)
    }

    /**
     * @var {HTMLDivElement} this.$refs.navbarNav
     */
    let navElement = this.$refs.navbarNav;
    this.navbar = new Collapse(navElement, {
      toggle: false
    });

    navElement.addEventListener('show.bs.collapse', () => {
      this.$el.classList.add('is-open');
      this.isOpen = true;
    });
    navElement.addEventListener('hide.bs.collapse', () => {
      this.$el.classList.remove('is-open');
      this.isOpen = false;
    });

    this.$root.$nav = this;
  },
  methods: {
    collapse() {
      this.navbar.hide();
      this.isOpen = false;
    },
    uncollapse() {
      this.navbar.show();
      this.isOpen = true;
    },
    toggle() {
      this.isOpen ? this.collapse() : this.uncollapse();
    }
  }
};
</script>

<style scoped>

</style>
