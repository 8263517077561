var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "nav",
    { ref: "nav", staticClass: "navbar", on: { click: _vm.collapse } },
    [
      _c(
        "div",
        { staticClass: "container ps-lg-0" },
        [
          _c("router-link", {
            staticClass: "navbar-icon d-block",
            attrs: { to: _vm.$user.loggedIn ? "/home" : "/login" },
            domProps: { innerHTML: _vm._s(_vm.logoIcon) },
          }),
          _vm._v(" "),
          _c("div", {
            staticClass: "navbar-toggler burger-menu-button d-lg-none",
            domProps: { innerHTML: _vm._s(_vm.burgerMenuIcon) },
            on: { click: _vm.toggle },
          }),
          _vm._v(" "),
          _vm.$navbar.badge
            ? _c("span", { staticClass: "badge badge-pill badge-primary" }, [
                _vm._v(_vm._s(_vm.$navbar.badge)),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            {
              ref: "navbarNav",
              staticClass: "navbar-collapse p-0 d-lg-none",
              attrs: { id: "nav" },
            },
            [
              _c(
                "ul",
                { staticClass: "navbar-nav" },
                _vm._l(_vm.links, function (link, key) {
                  return _c(
                    "li",
                    {
                      key: key,
                      class:
                        link.path === _vm.$router.currentRoute.path
                          ? "nav-item active"
                          : "nav-item",
                      on: { mouseup: _vm.collapse },
                    },
                    [
                      _c(
                        "router-link",
                        {
                          staticClass:
                            "nav-link text-center py-4 position-relative",
                          attrs: { to: link.path },
                        },
                        [
                          _vm._v(_vm._s(_vm.$t(link.name)) + "\n            "),
                          link.meta.badge
                            ? _c(
                                "span",
                                {
                                  staticClass: "badge badge-pill badge-primary",
                                },
                                [_vm._v(_vm._s(link.meta.badge))]
                              )
                            : _vm._e(),
                        ]
                      ),
                    ],
                    1
                  )
                }),
                0
              ),
            ]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }