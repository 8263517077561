import Plugin from './abstract';
import InitializedEvent from './initializedEvent';
import Vue from 'vue';

export {
  InitializedEvent,
  Plugin
};
/**
 * Die Leoparden AppCore Package
 *
 * Get most used default functionalities with this bundle! Don't forget to install the AppUI package, for more convenience!
 *
 * Usage: Either instantiate the exported module separately, or use it as a Vue Plugin with "Vue.use". Don't forget the kernel.config.js file in your root directory!
 *
 * @type {kernelConstructor}
 */
export default class Kernel {
  _Vue;

  config;

  constructor (config, Vue) {
    this._Vue = Vue;
    this.config = config;
  }

  static install (Vue, config) {
    let instance = new Kernel(config, Vue), page
    if (!Vue.prototype.$kernel)
      Object.defineProperty(Vue.prototype, '$kernel', {
        get () {
          return instance;
        }
      });
    if (!Vue.prototype.$page)
      Object.defineProperty(Vue.prototype, '$page', {
        get () {
          return page;
        },
        set (value) {
          page = value
        }
      });
  }


  /**
   *
   * @param {() => Promise<{default: Plugin}>} importFN
   * @param {Object} config configurationobject
   * @param {Kernel} [context] the context to intialise in
   * @param {string} [key] the propertyname in the given context
   */
  async initBundle (importFN, config, key = '') {
    try {
      const {default: bundle} = await importFN()
      Vue.use(bundle, { bundle: bundle, config: config, kernel: this, key });
    } catch (error) {
      console.log(key, error);
    }
  }
}
