/**
 * @type {kernel.database.Model}
 * @classdesc Default Model-Entity for Dexie-Abstraction
 */
export default class Model {
  static entityName = 'Model'
  static NON_INDEXED_FIELDS = 'NON_INDEXED_FIELDS';
  static ENCRYPT_LIST = 'ENCRYPT_LIST';
  static UNENCRYPTED_LIST = 'UNENCRYPTED_LIST';
  static NO_ENCRYPTION = 'NONE';
  /**
   * @var {String} Model.store - comma separated list of indexes like https://dexie.org/docs/Version/Version.stores()#detailed-schema-syntax
   */
  static store = 'id';
  /**
   * @var {Object<Array<Model.constructor.entityName|String>|Model.constructor.entityName|String>} Model.relations - if set, relations will automatically be set. Can be of Type Model or Array
   */
  static relations = {};

  /**
   * override the attributes of a class member
   * @type {Object}
   */
  static propertyOverrides = {};

  /**
   * the type of encryption method used
   * @type {string}
   */
  static encryption = 'NON_INDEXED_FIELDS';

  /**
   * @type {Database}
   * @private
   */
  _database;

  /**
   * @type {boolean}
   */
  isReady = false;
  /**
   * The data Storage of the Model --- do not touch!
   * @type {Object}
   * @private
   */
  __data = {};

  constructor() {
    this.constructor.store.split(',').map(key =>
        this.__createGetterSetter(key)
    );
  }

  /**
   *
   * @return {kernel.database.Model|Model} an empty Instance of this Model
   */
  static getInstance() {
    return new this.constructor();
  }

  /**
   *
   * @param str
   * @return {string}
   */
  static camelize(str) {
    return str.replace(/^\w|[A-Z]|\b\w/g, function (word, index) {
      return index === 0 ? word.toLowerCase() : word.toUpperCase();
    }).replace(/\s+/g, '');
  }

  /**
   *
   * @param str
   * @return {string}
   */
  static ucfirst(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  /**
   *
   * @param {string} key
   * @param {Array<typeof Model>} data
   * @param {Database} database
   * @return {Promise<never>}
   * @private
   */
  async __setInternalArrayData(key, data, database) {
    let ClassName = this.constructor.relations[key][0];
    const repository = database.getRepository(ClassName)
    if (!Array.isArray(data)) return
    const items = []
    for(const item of data) {
      let model
      if( !isNaN(parseInt(item))) {
        model = await database.getRepository(ClassName).find(item, this.__depth)
      } else if(typeof item === 'object' && item.hasOwnProperty('id') && !item.hasOwnProperty('persist')) {
        model = new (repository.class)()
        await model.setData(item, database)
      } else if(typeof item === 'object' && item.hasOwnProperty('persist')) {
        model = item
      }
      items.push(model)
    }
    this.__data[key] = items
  }

  /**
   * @param {string} key
   * @param {typeof Model} data
   * @param {Database} database
   * @return {Promise<never>}
   * @private
   */
  async __setInternalData(key, data, database) {
    let ClassName = this.constructor.relations[key];
    this.__data[key] = await this.__getInternalRelation(ClassName, data, database);
  }

  /**
   *
   * @param {string} key
   * @param {number} index
   * @return {Array<typeof Model>}
   * @private
   */
  __getInternalArrayData(key, index) {
    return this.__data[key][index];
  }

  /**
   *
   * @param {string} key
   * @return {typeof Model}
   * @private
   */
  __getInternalData(key) {
    return this.__data[key];
  }

  __depth = 0;

  /**
   *
   * @param {string} ClassName
   * @param {any} data
   * @param {Database} database
   * @return {Promise<any>}
   * @private
   */
  async __getInternalRelation(ClassName, data, database) {
    let model;
    if (data && isNaN(data) && database.classes[ClassName]) {
      model = new database.classes[ClassName](database);
      await model.setData(data, database)
    } else if (data && !isNaN(data)) {
      model = await database.getRepository(ClassName).find(data, this.__depth)
    }
    return model
  }

  /**
   * @param key
   * @private
   */
  __createGetterSetter(key) {
    let setter = Model.camelize('set ' + key);
    let getter = Model.camelize('get ' + key);

    if (!this.__data) {
      this.__data = {}
    }

    !this.hasOwnProperty(getter) && Object.defineProperty(this,
        getter, {
          name: getter,
          value: () => this.__data[key]
        }
    );

    !this.hasOwnProperty(setter) && Object.defineProperty(this,
        setter, {
          name: setter,
          value: (value) => {
            this.setDirty(true);
            this.__data[key] = value;
          }
        }
    );

    !this.hasOwnProperty(key) && Object.defineProperty(this, key, {
          get: () => this.__data[key],
          set: (data) => this.__data[key] = data,
          ...this.constructor.propertyOverrides[key]
        }
    );
  }

  /**
   *
   * @param key
   * @return {Number<0|1|2>} type of the relation
   * @private
   */
  __getRelationType(key) {
    return this.constructor.relations[key] ? Array.isArray(this.constructor.relations[key]) ? 2 : 1 : 0;
  }

  /**
   *
   * @param {Object|ApiResponse} data the entities Data. Id must be provided
   * @param {Database} database
   * @return {Promise<typeof Model|typeof database.Model>}
   */
  async setData(data, database) {
    if (!data) return this
    for (const [key, value] of Object.entries(data)) {
      this.__createGetterSetter(key);
      switch (this.__getRelationType(key)) {
        case 2:
          await this.__setInternalArrayData(key, value, database)
          break;
        case 1:
          await this.__setInternalData(key, value, database)
          break;
        default:
          this[Model.camelize('set ' + key)](value);
          break;
      }
    }
    this.isReady = true;
    return this

  }

  /**
   * Persist / Update function
   * @param {boolean} deep
   * @param {Database} database
   * @return {Promise<kernel.database.Model>}
   */
  async persist(deep = true, database) {
    for (const key of Object.keys(this.__data)) {

      let getter = Model.camelize('get ' + key);

      let Class = this.constructor.relations[key];

      if (Class && !Array.isArray(Class)) {
        this[getter]() && this[getter]().getDirty() && deep && await this[getter]().persist(deep, database);
      } else if (Class) {
        for (const item of this[getter]()) {
          item instanceof Model && item.getDirty() && deep && await item.persist(deep, database)
        }
      }
    }
    await database.getRepository(this.constructor.entityName).push(this.dump())

    this.setDirty(false);
    return this
  }

  /**
   * Returns data of this model for non-circular usage
   * @return {kernel.database.Model.__data|Object} - Model without relations
   */
  dump() {
    let data = {};
    Object.keys(this.__data).map(key => {
      let getter = Model.camelize('get ' + key);
      if (this.hasOwnProperty(getter) && this[getter]()) {
        switch (this.__getRelationType(key)) {
          case 2:
            data[key] = this[getter]().map(item => item?.getId()).filter(item => item);
            break;
          case 1:
            data[key] = this[getter]().getId();
            break;
          default:
            data[key] = this[getter]();
            break;
        }
      } else {
        data[key] = this[getter]();
      }
    });
    return data;
  }

  /**
   *
   * @param value
   */
  setDirty(value) {
    this._isDirty = value;
  }

  /**
   *
   * @return {boolean}
   */
  getDirty() {
    return this._isDirty;
  }

}
