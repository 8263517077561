/**
 * @type {kernel.kernelBundleConstructor}
 */
export default class Plugin {
  /**
   * private data collection
   * @type {kernel.database.Model.__data}
   */
  __data;
  /**
   * config object of the bundle
   * @type {Object}
   */
  config;
  /**
   * parent for stateful access
   * @type {Kernel}
   */
  _kernel;

  /**
   * @type {Boolean}
   */
  installed;

  /**
   * @type {VueConstructor}
   */
  app;

  /**
   *
   * @param {Object} config
   * @param {Kernel} _kernel
   */
  constructor (config, _kernel) {
    this._kernel = _kernel;
    this.config = config;
    config.additionalProperties && Object.entries(config.additionalProperties).map(([property, value]) => {
      this.define(property, value);
    });
  }

  /**
   * @type {string}
   * @private
   */
  _name;

  get name () {
    return this._name;
  }

  /**
   *
   * @return {Plugin.prototype}
   */
  get prototype () {
    return Plugin.prototype;
  }

  /**
   *
   * @param {VueConstructor<Vue>} Vue
   * @param {Object} config
   * @param {Plugin} bundle
   * @param {Kernel} kernel
   * @param {string} key
   */
  static install (Vue, { config = Object(), bundle, kernel, key }) {
    if (typeof bundle.create === 'function') {
      let instance = bundle.create(Vue, config, kernel);
      Vue.mixin({
        beforeCreate () {
          instance.app = this;
          if (typeof instance.config.declareReactive === 'object') {
            Object.entries(instance.config.declareReactive).map(([key, initialValue]) => {
              // noinspection JSCheckFunctionSignatures
              Vue.util.defineReactive(instance, key, initialValue);
            });
          }
        }
      });
      // noinspection JSCheckFunctionSignatures
      if(!(key in kernel)) {
        Object.defineProperty(kernel, key, {
          get () {
            return instance;
          }
        });
      }
      let pluginName = `$${key}`;
      if (!(pluginName in Vue.prototype)) {
        Object.defineProperty(Vue.prototype, pluginName, {
          get () {
            return instance;
          }
        });
      }
    } else {
      throw new TypeError(key + ' isn\'t compatible');
    }
  };

  /**
   * plugin install method
   * @param {VueConstructor} Vue
   * @param {kernel.auth.config} config
   * @param {Kernel} kernel
   * @return {Plugin}
   */
  static create(Vue, config, kernel) {
    const constructor = this
    return new constructor(config, kernel);
  };

  /**
   * dynamic getter/setter.
   * @param {String} key
   * @param {any} value
   */
  define (key, value = undefined) {
    Object.defineProperty(this, key, {
      get: () => this.__data[key],
      set: (data) => {
        this.__data[key] = data;
      }
    });
    if (typeof value !== 'undefined') {
      this[key] = value;
    }
  }

  getCore () {
    return this._kernel;
  }
}
