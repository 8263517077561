import Model from './model';

/**
 * @type {kernel.database.Repository}
 */
export default class Repository {


  /**
   * @typedef {'creating'|'deleting'|'reading'|'updating'} DexieEvents
   */

  /**
   * @type {typeof Model}
   */
  model;

  class;

  /**
   * @type {String}
   */
  className;

  /**
   * @type {Database}
   * @private
   */
  _database;


  // noinspection JSClosureCompilerSyntax
  /**
   * @param {typeof Model} Class
   * @param {Database} connection
   */
  constructor(Class, connection) {
    if (Class && !((new Class()) instanceof Model)) {
      throw new TypeError('Repository - Class is not defined. Aborting...');
    }
    this.model = Class.getInstance();

    this.className = Class.entityName;
    this._database = connection;
    this.class = Class
  }

  /**
   * find an entity via id
   * @param {Number} id
   * @return {Promise<Model>}
   */
  async find(id, depth = 0) {
    if (!id) {
      throw new Error('Please define a id');
    }
    if(depth > 3) return null
    const item = await this._database.table(this.className).get({id})
    if (item) {
      item.__depth = depth+1
      await item.setData(item, this._database)
      item?.setDirty(false);
    }
    return item
  }

  async findBy(ids, depth = 0) {
    const entities = await this._database.table(this.className).where('id').anyOf(ids).toArray()
    if(depth > 3 ) return []
    for (const item of entities) {
      item.__depth = depth+1
      await item.setData(item, this._database)
      item.setDirty(false);
    }
    return entities
  }

  /**
   * find all entities
   * @return {Promise<Array<kernel.database.Model>>}
   */
  async findAll(order = 'asc', orderKey = 'id', depth = 0) {
    const entities = await this._database.table(this.className).toArray()
    if(depth > 3 ) return []
    for (const item of entities) {
      item.__depth = depth+1
      await item.setData(item, this._database)
      item.setDirty(false);
    }
    entities.sort((modelA, modelB) => {
      if (modelA[orderKey] > modelB[orderKey]) return -1;
      if (modelA[orderKey] < modelB[orderKey]) return 1;
      return 0;
    });
    if (order !== 'asc') {
      entities.reverse();
    }
    return entities
  }

  async push(model) {
    if (model.id)
      await this._database.table(this.className)?.put(model, this.className)
    this._fireUpdateEvent(model);
  }

  /**
   *
   * @param {number|string} key
   * @return {Promise<void>}
   */
  async delete(key) {
    await this._database.table(this.className).delete(key)
    this._fireUpdateEvent(key);
  }

  /**
   * clears the repository table
   * @return {Promise<void>}
   */
  async truncate() {
    await this._database.table(this.className)?.clear()
    this._fireUpdateEvent(this.className);
  }

  _fireUpdateEvent(detail) {
    this._database._fireEvent('table_updated', this.className, detail);
  }

  /**
   * @param {DexieEvents} event
   * @param {kernel.database.Repository.EventHandler} callback
   */
  addEventListener(event, callback) {
    this._database.table(this.className).hook(event, callback);
  }

  /**
   * @param {DexieEvents} event
   * @param {kernel.database.Repository.EventHandler} callback
   */
  removeEventListener(event, callback) {
    // noinspection JSCheckFunctionSignatures
    this._database.table(this.className).hook(event).unsubscribe(callback);
  }

}
